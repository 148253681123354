import React from 'react';
import styles from './Skeleton.module.scss';

type SkeletonProps = {
	height?: string | number;
	width?: string | number;
	borderRadius?: string | number;
	type?: 'circle' | 'line' | 'rect';
};

const Skeleton = ({ height, borderRadius = '4rem', type, width }: SkeletonProps) => {
	return (
		<div className={styles.container}>
			{type === 'circle' && <div className={styles.circle} style={{ height, width, borderRadius: '50%' }} />}
			{type === 'line' && <div className={styles.line} style={{ height, width, borderRadius }} />}
			{type === 'rect' && <div className={styles.rect} style={{ height, width, borderRadius }} />}
		</div>
	);
};

export default Skeleton;
