import { useEffect, useMemo, useRef, useState } from 'react';
import type { Video as VideoType } from '@ts/shopify-storefront-api';

type VideoProps = {
	video: VideoType;
	className?: string;
	poster?: string;
	mobile?: boolean;
};

function Video({ video, className, poster, mobile }: VideoProps) {
	const playerRef = useRef<HTMLVideoElement>();
	const [playerError, setPlayerError] = useState(false);

	useEffect(() => {
		playerRef?.current &&
			playerRef.current
				.play()
				.then(() => {
					const isPlaying =
						playerRef.current.currentTime > 0 &&
						!playerRef.current.paused &&
						!playerRef.current.ended &&
						playerRef.current.readyState > playerRef.current.HAVE_CURRENT_DATA;

					if (isPlaying) {
						playerRef.current.pause();
					}

					playerRef.current.load();
				})
				.catch(e => {
					// Media was removed error is expected in resize events.
					// "setPlayerError" is used to show a fallback image on iOS low-power mode.
					if (!e.message.includes('media was removed')) {
						console.error(e);
						setPlayerError(true);
					}
				});
	}, [mobile]);

	const videoIOS = useMemo(() => {
		return video.sources.find(source => source.mimeType === 'video/mp4');
	}, [video]);

	return playerError ? (
		<picture className={className}>
			<source srcSet={videoIOS?.url} type={videoIOS?.mimeType} />
			<img src={poster} alt={video?.alt} />
		</picture>
	) : (
		<video
			key={video.sources[0].url}
			ref={playerRef}
			playsInline
			loop
			muted
			className={className}
			autoPlay
			poster={poster}
			style={{ width: '100%', height: '100%', objectFit: 'cover' }}
		>
			{video.sources.map(source => (
				<source key={source.url} src={source.url} type={source.mimeType} />
			))}
		</video>
	);
}

export default Video;
