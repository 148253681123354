import { CardProps } from '@ts/index';
import Card from '../Card/Card';

const VerticalCard = ({
	aspectRatio = '4/3',
	buttonGroupType = 'side-by-side',
	contentType = 'vertical',
	containerType = 'vertical',
	product,
	showCollectionLozenge = true,
	showTags = true,
	showDescription = true,
	supplemental,
	variant,
	...rest
}: Omit<CardProps, 'children'>) => {
	return (
		<Card
			aspectRatio={aspectRatio}
			buttonGroupType={buttonGroupType}
			containerType={containerType}
			contentType={contentType}
			product={product}
			showTags={showTags}
			showCollectionLozenge={showCollectionLozenge}
			showDescription={showDescription}
			supplemental={supplemental}
			variant={variant}
			{...rest}
		/>
	);
};

export default VerticalCard;
