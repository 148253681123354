import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

type PlusProps = {
	color?: string;
	width?: string | number;
	height?: string | number;
};

const Plus = ({ color = '#000', width = 12, height = 12 }: PlusProps) => {
	return (
		<AccessibleIcon.Root label='Plus'>
			<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' width={width} height={height}>
				<rect x='0' y='45' width='100' height='10' fill={color} />
				<rect x='45' y='0' width='10' height='100' fill={color} />
			</svg>
		</AccessibleIcon.Root>
	);
};

export default Plus;
