import { useState, useRef } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PRODUCT_TYPES } from '@constants';
import { Chevron, Flex, Heading, VerticalCard } from '@components';
import { NormalizedVariant } from '@ts/product';
import useRecommend from '@services/algolia/hooks/useRecommend';
import { getVariantByOption } from '@utils/shopify';
import 'swiper/css';
import styles from './RecommendationCarousel.module.scss';

type RecommendationCarouselProps = {
	variant: NormalizedVariant;
	selectedFrame: string;
	headingText?: string;
	productTypes?: (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES][];
	demo?: string;
	dataTags?: Record<
		'button' | 'zoom' | 'favorite',
		{
			[key: string]: string | boolean;
		}
	>;
};

const getCollectionHandle = (demo: string) => {
	switch (demo) {
		case 'men':
			return 'mens-frames';
		case 'women':
			return 'womens-frames';
		case 'kids':
			return 'kids-frames';
		default:
			return null;
	}
};

const RecommendationCarousel = ({
	variant,
	headingText,
	selectedFrame,
	productTypes = [PRODUCT_TYPES.TOP_FRAME],
	demo,
	dataTags,
}: RecommendationCarouselProps) => {
	const [isBeginning, setIsBeginning] = useState(false);
	const [isEnd, setIsEnd] = useState(false);

	const MAX_RECS = 8;

	const collectionHandle = getCollectionHandle(demo);

	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);

	const navigationRefs = {
		prevEl: navigationPrevRef.current,
		nextEl: navigationNextRef.current,
	};

	const [{ recommendations }] = useRecommend({
		objectIds: [`${variant?.id}`],
		types: productTypes,
		collectionHandle,
		maxRecommendations: MAX_RECS,
	});

	const mappedRecommendations =
		recommendations &&
		variant &&
		recommendations.map(product => {
			if (!product || product.name === 'PAIRCare') return;

			let selectedVariant = product.variants[0];

			if (variant?.type.includes(PRODUCT_TYPES.TOP_FRAME)) {
				selectedVariant = getVariantByOption(product, selectedFrame);
			}

			if (!selectedVariant?.availableForSale) return;

			// currently-featured Base Frames shouldn't recommend themselves
			if (product.type.includes(PRODUCT_TYPES.BASE_FRAME) && product.name === variant.name) {
				return null;
			}

			return (
				<SwiperSlide
					key={`recommendation-${product.id}-${product.name}`}
					className={product.type === PRODUCT_TYPES.ACCESSORY ? styles.accessorySlider : styles.collectionSlider}
				>
					{product.type.includes(PRODUCT_TYPES.BASE_FRAME) ? (
						<VerticalCard
							primaryAction='view'
							product={product}
							showVariantControls
							variant={selectedVariant}
							label={`Explore ${product.name}`}
							secondaryAction='none'
							dataTags={dataTags}
						/>
					) : (
						<VerticalCard
							showTags
							showCollectionLozenge={true}
							aspectRatio={'4/3'}
							product={product}
							variant={{
								...selectedVariant,
								option: selectedVariant?.option !== 'Default Title' ? selectedVariant?.option : 'Fill in Blank',
							}}
							dataTags={dataTags}
							secondaryAction='favorite'
							{...(selectedVariant?.option === 'Default Title'
								? {
										description: <span style={{ color: 'transparent' }}>{'-'}</span>,
									}
								: {})}
						/>
					)}
				</SwiperSlide>
			);
		});

	return (
		<div className={styles.container} data-recommendation-carousel={variant?.type}>
			<Heading tag='h4' className={styles.heading}>
				{headingText}
			</Heading>
			<div className={styles.collectionTrack}>
				<Swiper
					autoplay={false}
					direction='horizontal'
					modules={[Navigation]}
					navigation={navigationRefs}
					onSlidesGridLengthChange={swiper => {
						setIsBeginning(swiper.isBeginning);
						setIsEnd(swiper.isEnd);
						swiper.on('reachEnd', () => {
							setIsEnd(true);
						});
					}}
					onSlideChange={swiper => {
						setIsBeginning(swiper.isBeginning);
						setIsEnd(swiper.isEnd);
					}}
					slidesPerView='auto'
					spaceBetween={16}
				>
					<ul>{recommendations ? mappedRecommendations : null}</ul>
					<Flex fullWidth justify='end' gap={3} className={styles['navigation-container']}>
						<div className={`${styles.prevButton} ${isBeginning ? styles.disabled : ''}`} ref={navigationPrevRef}>
							<Chevron direction='left' />
						</div>
						<div className={`${styles.nextButton} ${isEnd ? styles.disabled : ''}`} ref={navigationNextRef}>
							<Chevron direction='right' />
						</div>
					</Flex>
				</Swiper>
			</div>
		</div>
	);
};

export default RecommendationCarousel;
